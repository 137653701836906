<template>
    <div class="Profile-home-works home-works">
        <div class="home-works__filter">
            <el-radio-group v-model="status" @change="getHomeworks(currentPage)">
                <el-radio-button label="3">{{ $t('profile.home-works.current') }}</el-radio-button>
                <el-radio-button label="2">{{ $t('profile.home-works.lost') }}</el-radio-button>
                <el-radio-button label="4">{{ $t('profile.home-works.checked') }}</el-radio-button>
            </el-radio-group>
        </div>
        <div class="home-works__header">
            <div class="home-works__sections-rows">
                <form-group style="margin-bottom: 1rem">
                    <el-select
                        :placeholder="$t('profile.home-works.all-subjects')"
                        clearable
                        v-model="subject_id">
                        <el-option
                            v-for="item in subjects"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </form-group>
                <form-group style="margin-bottom: 1rem">
                    <el-select
                        :placeholder="$t('profile.home-works.all-classes')"
                        clearable
                        @change="getStudents"
                        v-model="class_id">
                        <el-option
                            v-for="item in classes"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </form-group>
            </div>
            <div class="home-works__sections-rows">
                <form-group style="margin-bottom: 1rem">
                    <el-select
                        :placeholder="$t('profile.home-works.all-students')"
                        clearable
                        v-model="student_id">
                        <el-option
                            v-for="(item, index) in students"
                            :key="index"
                            :label="item"
                            :value="index">
                        </el-option>
                    </el-select>
                </form-group>
                <form-group style="margin-bottom: 1rem">
                    <el-date-picker
                        :picker-options="pickerOptions"
                        :placeholder="$t('profile.homework-filter-date')"
                        value-format="yyyy-MM-dd"
                        v-model="deadline">
                    </el-date-picker>
                </form-group>
            </div>
            <div class="home-works__sections-rows">
                <div class="home-work-btn-wrapper" style="margin-top: 55px; margin-bottom: 1rem">
                    <el-button @click="resetFilter" class="btn-purple-clr">{{ $t('profile.notifications.btn-clear') }}</el-button>
                    <el-button :loading="filterLoading" @click="applyFilter" class="btn-purple" type="primary">{{ $t('profile.detailed-lesson.apply') }}</el-button>
                </div>
            </div>
        </div>
        <div class="home-works__section">
            <div class="home-works__section-rows">
                <div class="row" v-loading="loadingService">
                    <div class="col-lg-3 col-md-6" v-for="homework in homeworks">
                        <div class="hw-card" :class="{
                            'hw-card--pink': homework.status === 2,
                            'hw-card--green': homework.status === 4
                        }">
                            <div class="hw-card__header">
                                <div class="hw-card__title">
                                    {{ homework.subject_name }}
                                </div>
                                <div class="hw-card__btns">
                                    <img v-if="homework.topic_content_type_id === '1'" src="/images/profile/edit-icon.svg" width="18" height="18" alt="">
                                    <img v-if="homework.topic_content_type_id === '2'" src="/images/profile/write-icon.svg" width="18" height="18" alt="">
                                    <img v-if="homework.topic_content_type_id === '3'" src="/images/profile/check-icon.svg" width="18" height="18" alt="">
                                    <el-popover
                                        placement="bottom"
                                        width="264"
                                        trigger="click"
                                        :popper-class="getPopperClass(homework.status)">
                                        <div class="info-popper__title">
                                            {{ $t('profile.home-works.popper-title') }}
                                        </div>
                                        <div class="info-popper__status">
                                            {{ $t('profile.home-works.popper-status-' + homework.status) }}
                                        </div>
                                        <div class="info-popper__text">
                                            {{ $t('profile.home-works.popper-subject') }}{{ homework.subject_name }}
                                        </div>
                                        <div class="info-popper__text" v-if="homework.topic.name">
                                            {{ $t('profile.home-works.popper-theme') }}{{ homework.topic.name }}
                                        </div>
                                        <div class="info-popper__text" v-if="homework.topic_content_type">
                                            {{ $t('profile.home-works.popper-type') }}{{ homework.topic_content_type }}
                                        </div>
                                        <div class="info-popper__text">
                                            {{ $t('profile.home-works.popper-student') }}{{ homework.student.last_name }} {{ homework.student.first_name }} {{ homework.student.patronymic }}
                                        </div>
                                        <div class="info-popper__date">
                                            {{ $t('profile.home-works.popper-date') }}{{ homework.created_at }}
                                        </div>
                                        <el-button slot="reference" class="hw-card__btn">
                                            <img src="/images/profile/info-icon.svg" width="18" height="18" alt="">
                                        </el-button>
                                    </el-popover>
                                </div>
                            </div>
                            <div class="hw-card__body" @click.stop="$router.push({ name: 'homework', params: { id: homework.id }})" style="cursor: pointer">
                                <div class="hw-card-body-pencil-red" v-if="homework.status === 2"></div>
                                <div class="hw-card-body-pencil" v-if="homework.status === 3"></div>
                                <div class="hw-card__result" v-if="homework.status === 4">
                                    {{ homework.score }}
                                </div>
                            </div>
                            <div class="hw-card__footer">
                                <div>
                                    {{ $t('profile.home-works.pass-till') }} {{ moment(homework.hw.deadline).format('DD-MM-YY') }}
                                </div>
                                <div v-if="homework.status === 4">
                                    {{ $t('profile.home-works.passed') }} {{ homework.passed }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-pagination
            v-if="total > perPage"
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            last-number
            first-number
            @change="getHomeworks"
            hide-goto-end-buttons
            align="center"
            next-text=">"
            prev-text="<">
        </b-pagination>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pickerOptions: {
                firstDayOfWeek: 1
            },
            loadingTimeout: false,
            loadingService: false,
            filterLoading: false,
            total: 0,
            perPage: 0,
            currentPage: 1,
            homeworks: [],
            subject_id: '',
            class_id: '',
            student_id: '',
            filterDate: '',
            status: '3',
            deadline: '',
            subjects: [],
            classes: [],
            students: []
        }
    },
    mounted() {
        this.getHomeworks(1)
    },
    methods: {
        getHomeworks(page) {
            this.loadingService = true;
            this.loadingTimeout = setTimeout(() => {
                this.loadingService = false;
            }, 5000);
            let subjectId = this.subject_id
            let classId = this.class_id
            let studentId = this.student_id
            let status = this.status
            let deadline = this.deadline
            this.$http.get(`${window.API_ROOT}/api/teacher/homeworks?page=${page}&subject=${subjectId}&class=${classId}&student=${studentId}&status=${status}&deadline=${deadline}`)
                .then((res) => {
                    this.homeworks = res.body.data
                    this.subjects = res.body.subjects
                    this.classes = res.body.classes
                    this.total = res.body.total
                    this.perPage = res.body.perPage
                    this.filterLoading = false
                    this.loadingService = false
                    window.clearTimeout(this.loadingTimeout)
                })
                .catch(() => {
                    this.filterLoading = false
                    this.loadingService = false
                    window.clearTimeout(this.loadingTimeout)
                })
        },
        getStudents(classId) {
            this.$http.get(`${window.API_ROOT}/api/teacher/students/${classId}`)
                .then((res) => {
                    this.students = res.body
                })
                .catch((e) => {
                    console.log(e);
                })
        },
        getPopperClass(id) {
            switch (id) {
                case 2:
                    return 'info-popper info-popper--pink'
                case 4:
                    return 'info-popper info-popper--green'
                default:
                    return 'info-popper'
            }
        },
        applyFilter() {
            this.filterLoading = true;
            this.getHomeworks(this.currentPage)
        },
        resetFilter() {
            this.subject_id = ''
            this.class_id = ''
            this.student_id = ''
            this.deadline = ''
            this.getHomeworks(this.currentPage)
        }
    },
}
</script>
<style lang="less" scoped>
/deep/ .el-loading-mask{
    background-color: transparent !important;
}
.pagination {
    border: none;
    box-shadow: none;
    margin-bottom: 30px;
    padding-top: 50px;
}
/deep/ .pagination .page-item .page-link {
    background: #EEEAFB;
    color: #473F95;
    border-radius: 5px;
    height: 45px;
    min-width: 58px;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    border: none;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/deep/ .pagination .page-item[role=separator] .page-link {
    background: transparent;
    font-size: 28px;
    display: flex;
    align-items: flex-start;
}
/deep/ .pagination .page-item.active .page-link {
    background: #473F95;
    color: #fff;
}
/deep/ .pagination .page-item.active .page-link:focus {
    box-shadow: none;
}
/deep/ .pagination .page-item:last-child .page-link:hover,
/deep/ .pagination .page-item:first-child .page-link:hover {
    background: #EEEAFB;
    color: #473F95;
}
/deep/ .page-link:focus {
    box-shadow: none;
}
.home-works{
    width: 100%;
}
.el-radio-group {
    width: 100%;
}
.el-radio-group .el-radio-button {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    color: #473F95;
    width: 33%;
}
/deep/ .el-radio-button__inner{
    width: 100%;
    background: none;
    color: #473F95;
    border-color: #473F95;
}
/deep/ .is-active .el-radio-button__inner:hover {
    color: #fff !important;
}
/deep/ .el-radio-button:first-child .el-radio-button__inner{
    border-color: #473F95
}
/deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner{
    background-color: #473F95;
    border-color: #473F95;
    box-shadow: -1px 0 0 0 #473f95;
}
/deep/ .el-radio-button__inner:hover {
    color: #473F95;
}
/deep/ .el-radio-button:first-child .el-radio-button__inner {
    border-radius: 5px 0 0 5px;
}
/deep/ .el-radio-button:last-child .el-radio-button__inner {
    border-radius: 0 5px 5px 0;
}
.el-select {
    width: 100%;
}
.el-date-editor{
    width: 100%;
}
.home-works__filter{
    line-height:36px;
    text-align: center;
    display: flex;
    margin: 0 auto 5rem;
    width:600px;
}
.home-works__filer-first{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    color: #473F95;
    border-radius: 5px 0 0 5px;
    border: 1px solid #473F95;
    width:33%;

}
.home-works__filer-center{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    color: #473F95;
    border: 1px solid #473F95;
    width:33%
}
.home-works__filer-end{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    color: #473F95;
    border-radius: 0 5px 5px 0;
    border: 1px solid #473F95;
    width:33%
}
.home-work-btn-wrapper {
    display: flex;
    justify-content: space-between;
}

.btn-purple-clr {
    cursor: pointer;
    user-select: none;
    border-radius:5px;
    line-height:38px;
    border: 1px solid #473F95;
    background: none;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #473F95;
    width: 46%;
    padding: 0;
}

.btn-purple {
    cursor: pointer;
    user-select: none;
    border-radius:5px;
    line-height:38px;
    background-color:  #473F95;
    border-color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: white;
    width: 46%;
    padding: 0;
}

.home-works__sections-rows {
    width: 32%;
    display: flex;
    flex-direction: column;
}

.Profile-home-works {
    color: #303030;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    max-width: 100%;
    margin: 0 0 114px 0;
}

/deep/ .el-input__inner {
    min-width: 260px;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 4px;
}

.el-select-dropdown__item.selected {
    color: #473F95;
}

/deep/ .el-select .el-input__inner:focus {
    border-color: #E0E0E0;
}

/deep/ .el-input__inner::placeholder {
    color: #303030;
    font-size: 13px;
}

.home-works__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;
    margin-top: 48px;
}

.home-works__header .form-group {
    margin-bottom: 0;
}

.home-works__section-title {
    margin-bottom: 20px;
}

.hw-card {
    margin-bottom: 30px;
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    min-width: 220px;
    min-height: 202px;
    position: relative;
}

.hw-card__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 12px 0 12px;
    align-items: baseline;
}

.hw-card__btns {
    display: flex;
    flex-direction: row;
}

.hw-card__btns .hw-card__btn:last-child {
    margin-left: 15px;
}

.hw-card__btn {
    border: none;
    background-color: #fff;
    padding: 0;
    display: flex;
    align-items: center;
}

.hw-card__body {
    background: url("/images/profile/bg-purple.svg") no-repeat top;
    background-size: cover;
    height: calc(202px - 100px);
    position: absolute;
    bottom: 67px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hw-card__footer {
    color: #fff;
    background: #473F95;
    border-radius: 0 0 10px 10px;
    padding: 24px 12px;
    font-size: 16px;
    line-height: 19px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.hw-card__title {
    max-width: 105px;
}

.home-works__section-rows .row:last-child {
    margin-top: 30px;
    margin-bottom: 60px;
}

.hw-card-body-pencil {
    background-color: white;
    height: 75px;
    width: 75px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 75px;
    background-image: url("/images/pen-homework-teacher.svg");
}

.hw-card-body-pencil-red {
    background-color: white;
    height: 75px;
    width: 75px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 75px;
    background-image: url("/images/pen-homework-teacher-red.svg");
}

.hw-card--pink .hw-card__body {
    background-image: url("/images/profile/bg-pink.svg");
}

.hw-card--pink .hw-card__footer {
    background: #D23168;
}

.hw-card--yellow .hw-card__body {
    background-image: url("/images/profile/bg-yellow.svg");
}

.hw-card--yellow .hw-card__footer {
    background: #DE9A06;
}

.hw-card--green .hw-card__body {
    background-image: url("/images/profile/bg-green.svg");
}

.hw-card--green .hw-card__footer {
    background: #55953F;
}

.hw-card--green .hw-card__footer {
    padding: 12px;
    min-height: 67px;
}

.home-works__more {
    text-decoration: underline;
    border: none;
    background-color: transparent;
    margin-bottom: 20px;
}

.hw-card__result {
    color: #55953F;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 50%;
}

.info-popper {
    padding: 12px;
    font-family: Roboto, sans-serif;
}

.info-popper__title {
    color: #473F95;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
}

.info-popper__status {
    color: #473F95;
    padding: 13px;
    font-size: 18px;
    line-height: 21px;
    background: #EEEAFB;
    border-radius: 10px;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.info-popper__text {
    padding-bottom: 15px;
    color: #473F95;
    font-size: 16px;
    line-height: 19px;
    word-wrap: break-word;
}

.info-popper__date {
    color: #473F95;
    font-size: 18px;
    line-height: 21px;
    padding-top: 10px;
    border-top: 1px solid #473F95;
}

.info-popper--yellow .info-popper__date {
    color: #DE9A06;
    border-top: 1px solid #DE9A06;
}

.info-popper--yellow .info-popper__status {
    color: #DE9A06;
    background: #FFF7CE;
}

.info-popper--green .info-popper__date {
    color: #55953F;
    border-top: 1px solid #55953F;
}

.info-popper--green .info-popper__status {
    color: #55953F;
    background: #F4FBEA;
}

.info-popper--pink .info-popper__date {
    color: #D23168;
    border-top: 1px solid #D23168;
}

.info-popper--pink .info-popper__status {
    color: #D23168;
    background: #FBEAEA;
}

@media (max-width: 1200px) {
    .hw-card {
        min-width: 180px;
    }

    .hw-card__btns .hw-card__btn:last-child {
        margin-left: 8px;
    }
}
@media (max-width: 991px) {
    .home-works__filter {
        width: 100%;
        margin-bottom: 0;
    }
    .el-radio-group {
        width: 100%;
        white-space: nowrap;
        overflow-x: auto;
    }
    .el-radio-group .el-radio-button {
        width: auto;
        margin-right: 12px;
        margin-bottom: 0;
    }
    /deep/ .el-radio-button:first-child .el-radio-button__inner,
    /deep/ .el-radio-button:last-child .el-radio-button__inner,
    /deep/ .el-radio-button .el-radio-button__inner {
        border-radius: 12px;
        border: 1px solid #473F95;
        padding: 12px 14px;
    }
    /deep/ .el-radio-button:last-child {
        margin-right: 0;
    }
    .home-works__header {
        margin-top: 24px;
        margin-bottom: 24px;
        flex-direction: column;
    }
    .home-works__sections-rows {
        width: 100%;
    }
    .home-work-btn-wrapper {
        margin-top: 8px!important;
        margin-bottom: 24px;
    }
    .home-work-btn-wrapper {
        justify-content: center;
    }
    .btn-purple {
        width: 150px;
        margin-left: 12px;
    }
    .btn-purple-clr {
        width: 150px;
    }
    .hw-card {
        max-width: 312px;
        margin: 0 auto;
    }
    .hw-card__body {
        // background-size: 100% 80px;
    }
}
@media (max-width: 767px) {
    .hw-card {
        margin-bottom: 30px;
    }
    /deep/ .pagination {
       padding-top: 0;
       margin-bottom: 0;
    }
    /deep/ .pagination .page-item .page-link {
        font-size: 14px;
        line-height: 16px;
        min-width: 38px;
    }
    .btn-purple,
    .btn-purple-clr {
        width: 100%;
    }
}

</style>